<template>
  <v-layout
    column
    justify-center
    fill-height
    ref="layout"
    :class="{ mobile: mobile }"
  >
    <!-- Mission question -->
    <v-flex
      d-flex
      class="mission-instructions-row"
      :class="{ 'full-size': !showOptions, invisible: mode == 'social' }"
    >
      <v-flex d-flex>
        <ResizableText :message="mission.instructions" />
      </v-flex>
    </v-flex>

    <!-- Options -->
    <SlideOpacityTransition>
      <template v-if="canNominateScribe">
        <OptionsList
          v-if="!isHost && !isPresenterUser"
          :show="showOptions"
          :options="teamUsers"
        >
          <template #option="{ option }">
            <ChoiceBtn
              :inactive="!canSubmitPoll"
              :correct="isOptionCorrect(option.id)"
              @click="submit(option.id, option)"
            >
              <UserName
                :firstname="option.firstname"
                :lastname="option.lastname"
              />
            </ChoiceBtn>
          </template>
        </OptionsList>
      </template>

      <OptionsList v-else :show="showOptions" :options="multipleChoice">
        <template #option="{ option, index }">
          <v-tooltip top :disabled="!option.instructions">
            <template v-slot:activator="{ on }">
              <ChoiceBtn
                v-on="on"
                :inactive="!canSubmitPoll"
                :correct="isOptionCorrect(index)"
                @click="submit(index, option)"
              >
                {{ pollString(option.title) }}
              </ChoiceBtn>
            </template>
            <div
              class="top"
              style="text-align: center; max-width: 240px"
              v-if="option.instructions"
              v-html="option.instructions"
            />
          </v-tooltip>
        </template>
      </OptionsList>
    </SlideOpacityTransition>

    <!-- Status -->
    <MissionStatus
      v-if="showMissonstatusText"
      hideInstant
      :correct="missionCompleted"
      :show="showOptions"
    >
      {{ statusText }}
    </MissionStatus>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import { GameMixin } from "@/mixins"
import Ellipsis from "@/components/GroupTeams/Common/Games/GameCardParts/Ellipsis"
import MissionStatus from "@/components/GroupTeams/Common/Games/GameCardParts/MissionStatus"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import ChoiceBtn from "@/components/GroupTeams/Common/Games/GameCardParts/ChoiceBtn"
import OptionsList from "@/components/GroupTeams/Common/Games/GameCardParts/OptionsList"
import SlideOpacityTransition from "@/components/GroupTeams/Common/Games/GameCardParts/SlideFadeTransition.vue"

import User from "@shared/User"
import UserName from "@/components/GroupTeams/Common/User/UserName.vue"

export default {
  name: "Poll",
  components: {
    SlideOpacityTransition,
    UserName,
    OptionsList,
    ChoiceBtn,
    Ellipsis,
    MissionStatus,
    ResizableText
  },
  mixins: [GameMixin],
  props: {
    mode: String,
    mission: Object,
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      submited: false,
      visibleOptions: true,
      visible: true
    }
  },
  computed: {
    ...mapGetters([
      "missionCompleted",
      "missionUserPlaysArray",
      "missionAnswers",
      "gameStatus"
    ]),
    ...mapGetters("group", ["isMultiTeam"]),
    ...mapGetters("GameUsers", ["usersOnlineArray"]),
    isPresenterUser() {
      return User.isPresenter(this.user)
    },
    user() {
      return this.$store.getters.user
    },
    teamUsers() {
      return this.usersOnlineArray.filter(
        user => user.teamID === this.user.teamID
      )
    },
    isWaiting() {
      return !this.isScribe && !this.missionCompleted
    },
    statusText() {
      if (this.missionCompleted) {
        return "Submitted"
      }

      if (this.isWaiting) {
        return "Waiting on Scribe..."
      }

      if (!this.isHost && this.isScribe && !this.missionCompleted) {
        return "Please Select"
      }

      return ""
    },

    showOptions() {
      return this.mode === "play" || this.mode === "huddle"
    },
    multipleChoice() {
      const { mission } = this

      if (mission?.trw) return this.gameStatus?.trwOptions ?? []

      if (this.canNominateScribe) {
        return this.teamUsers.reduce((acc, user) => {
          acc[user.id] = user.id
          return acc
        }, {})
      }

      const { answer1, answer2, answer3, answer4, answer5 } = mission

      return [answer1, answer2, answer3, answer4, answer5].reduce(
        (acc, title) => {
          if (title) {
            acc.push({ title })
          }
          return acc
        },
        []
      )
    },
    isScribe() {
      return this.$store.getters.isScribe
    },
    canNominateScribe() {
      return this.mission.nominateScribe
    },
    isHost() {
      return this.user.role == "facilitator"
    },
    hasUserSubmitted() {
      return Boolean(this.missionUserPlaysArray.length)
    },
    canSubmitPoll() {
      return !this.hasUserSubmitted
    },
    showMissonstatusText() {
      return !this.isPresenterUser && !this.mobile
    }
  },
  watch: {
    missionCompleted(newValue, oldValue) {
      if (!newValue && oldValue) this.submited = false
    }
  },
  methods: {
    pollString(str) {
      if (str.indexOf(" -- ") > 0) return str.substring(0, str.indexOf(" -- "))
      return str
    },
    isGiven(idx) {
      return this.missionAnswers.indexOf(this.multipleChoice[idx]) > -1
    },
    isOptionCorrect(idx) {
      return this.isGiven(idx)
    },
    async submit(n) {
      if (!this.canSubmitPoll) return
      this.multiAnswer = n
      await this.checkAnswer()
    }
  }
}
</script>

<style scoped lang="scss">
.theme--dark.v-btn {
  background-color: transparent !important;
}
.mission-instructions-row {
  user-select: none;
  max-height: 40%;
  padding-left: 5px;
  padding-right: 5px;
  margin: 25px 0 0;
}

.mission-instructions-row.full-size {
  max-height: 100%;
  margin-top: 21px;
  transition: all 0s ease-out 0.25s;
}

.mission-instructions-row.invisible {
  visibility: hidden;
}

.mission-instructions {
  color: $color-black;
  padding: 10px;
  line-height: 1.2em;
}

.appear-enter {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.appear-enter-active {
  opacity: 1;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      ref: "layout",
      class: { mobile: _vm.mobile },
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _c(
        "v-flex",
        {
          staticClass: "mission-instructions-row",
          class: {
            "full-size": !_vm.showOptions,
            invisible: _vm.mode == "social",
          },
          attrs: { "d-flex": "" },
        },
        [
          _c(
            "v-flex",
            { attrs: { "d-flex": "" } },
            [
              _c("ResizableText", {
                attrs: { message: _vm.mission.instructions },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "SlideOpacityTransition",
        [
          _vm.canNominateScribe
            ? [
                !_vm.isHost && !_vm.isPresenterUser
                  ? _c("OptionsList", {
                      attrs: { show: _vm.showOptions, options: _vm.teamUsers },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "option",
                            fn: function (ref) {
                              var option = ref.option
                              return [
                                _c(
                                  "ChoiceBtn",
                                  {
                                    attrs: {
                                      inactive: !_vm.canSubmitPoll,
                                      correct: _vm.isOptionCorrect(option.id),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.submit(option.id, option)
                                      },
                                    },
                                  },
                                  [
                                    _c("UserName", {
                                      attrs: {
                                        firstname: option.firstname,
                                        lastname: option.lastname,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2843765216
                      ),
                    })
                  : _vm._e(),
              ]
            : _c("OptionsList", {
                attrs: { show: _vm.showOptions, options: _vm.multipleChoice },
                scopedSlots: _vm._u([
                  {
                    key: "option",
                    fn: function (ref) {
                      var option = ref.option
                      var index = ref.index
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "", disabled: !option.instructions },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "ChoiceBtn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              inactive: !_vm.canSubmitPoll,
                                              correct:
                                                _vm.isOptionCorrect(index),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.submit(index, option)
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.pollString(option.title)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            option.instructions
                              ? _c("div", {
                                  staticClass: "top",
                                  staticStyle: {
                                    "text-align": "center",
                                    "max-width": "240px",
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(option.instructions),
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
        ],
        2
      ),
      _vm.showMissonstatusText
        ? _c(
            "MissionStatus",
            {
              attrs: {
                hideInstant: "",
                correct: _vm.missionCompleted,
                show: _vm.showOptions,
              },
            },
            [_vm._v(" " + _vm._s(_vm.statusText) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }